<template>
  <div class="vehicleManagePage">
   <div class="topView" style="display: block; padding-bottom: 20px">
      <div class="searchBox" style="display: block">
        <div class="btn_box">
          <el-button type="success" size="medium"  @click="addvehicle()" icon="el-icon-circle-plus-outline">添加</el-button>
          <el-button icon="el-icon-wallet" type="primary" size="medium" @click="$router.push('/platform/carManage/importVehicle')">导入车辆表格</el-button>
          <el-button icon="el-icon-download" type="primary" size="medium" @click="download">下载批量导入模板</el-button>
        </div>
        <el-form class="vehicleForm" :model="vehicleForm" ref="vehicleForm">
          <el-form-item label="绑定车牌：" prop="keyword" label-width="90px">
            <el-input v-model="vehicleForm.VehicleCarNumber" placeholder="请输入车牌号"></el-input>
          </el-form-item>
          <el-form-item label="车辆类型" prop="VehicleCarNumber" label-width="96px">
              <el-select  v-model="vehicleForm.VehicleType" placeholder="请选择车辆类型" style="width:202px;">
                <el-option style="width:202px;" v-for="(item,index) in vehicleTypeList" :key="index" :value="item.Code" :label="item.Name"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item style="padding-left: 18px">
            <el-button type="primary" size="medium" @click="search('vehicleForm')" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="medium" @click="resetForm('vehicleForm')" icon="el-icon-delete">清空</el-button>
          </el-form-item>
        </el-form>
        
      </div>
    <!-- 表格区域 -->
      <el-table v-loading="loading" :data="tableData" @selection-change="tableSelectionChange" :header-cell-style="{background:'#f0f0f0',color:'#666'}">
        <el-table-column fixed align="center" type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column align="center" prop="VehicleCarNumber" label="车牌号" width="100"/>
        <el-table-column align="center" prop="CarNumberColorName" label="牌照颜色"/>
        <el-table-column align="center" prop="VehicleTypeName" label="车辆类型"/>
        <el-table-column align="center" prop="IsLeaderName" label="是否头车"/>
        <el-table-column align="center" prop="VehicleRTP" label="道路运输证号" width="200"/>
        <el-table-column align="center" prop="VehicleRTPDate" label="运输证有效期至" width="200"/>
        <el-table-column align="center" prop="VehicleLoad" label="载重（吨）" width="100"/>
        <el-table-column align="center" prop="VehicleLength" label="车长（米）" width="100"/>
        <el-table-column align="center" prop="VehicleWidth" label="车宽（米）"  width="100"/>
        <el-table-column align="center" prop="VehicleHeight" label="车高（米）" width="100"/>
        <el-table-column align="center" prop="VehicleAxlesNumber" label="轴数"/>
        <el-table-column align="center" prop="PowerNumber" label="发动机号" width="200"/>
        <el-table-column align="center" prop="VehiclePowerType" label="能源类型"/>
        <el-table-column align="center" prop="CInsuranceDate" label="交强险到期日" width="200"/>
        <el-table-column align="center" prop="BInsuranceDate" label="商业险到期日" width="200"/>
         <el-table-column align="center" prop="VehicleLicenseValidDate" label="行驶证有效期限" width="200"/>
        <el-table-column align="center" prop="VehicleBuyDate" label="购车日期" width="200"/>
        <el-table-column align="center" prop="InspectAnnuallyDate" label="年审日期" width="200"/>
        <el-table-column align="center" prop="Status" label="车辆认证状态" width="120"/>
        <el-table-column align="center" prop="Remark" label="认证失败原因" width="200"/>
        <el-table-column fixed="right" align="center" label="操作" width="190">
          <template slot-scope="scope">
            <el-button @click="goVerifyPage(scope.row)" type="primary" size="small" title="编辑" icon="el-icon-edit">编辑</el-button>
            <el-button @click="delVehicleInfo(scope.row)" type="danger" size="small" title="删除" icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination class="pagination" @current-change="handleCurrentChange" :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next" :total="pagination.total">
      </el-pagination>
    </div>
    <!-- 选择车牌类型dialog -->
    <el-dialog class="vehicleType" :visible.sync="showAddDialog" width="1000px" :before-close="handleClose">
      <span slot="title">{{title}}</span>
      <div class="body">
        <el-form class="addForm" :model="addForm" ref="addForm" :rules="addRule" label-width="120px">
          <el-form-item label="车辆行驶证正页" prop="vehicleLicenseFrontPageURL" label-width="160px">
            <UploadImg 
              :loading="confirmInfoForm.vehicleFace.loading" 
              :uploaded="confirmInfoForm.vehicleFace.uploaded" 
              :picUrl="confirmInfoForm.vehicleFace.picUrl" 
              @update:file="updateFile" 
              @change="uploadVehicleFace(confirmInfoForm.vehicleFace)" 
              @deleteImg="deleteImg(confirmInfoForm.vehicleFace)">
              </UploadImg>
          </el-form-item>
          <el-form-item label="车辆行驶证副页(正面)" prop="vehicleLicenseSubPageOnURL" label-width="160px">
            <UploadImg :loading="confirmInfoForm.vehicleBack.loading" :uploaded="confirmInfoForm.vehicleBack.uploaded" :picUrl="confirmInfoForm.vehicleBack.picUrl" @update:file="updateFile" @change="uploadVehicleBack(confirmInfoForm.vehicleBack)" @deleteImg="deleteImg(confirmInfoForm.vehicleBack)"></UploadImg>
          </el-form-item>
          <el-form-item label="车辆行驶证副页(反面)" prop="vehicleLicenseSubPageBackURL" label-width="160px">
            <UploadImg :loading="confirmInfoForm.vehiclePageOn.loading" :uploaded="confirmInfoForm.vehiclePageOn.uploaded" :picUrl="confirmInfoForm.vehiclePageOn.picUrl" @update:file="updateFile" @change="uploadVehiclePageOn(confirmInfoForm.vehiclePageOn)" @deleteImg="deleteImg(confirmInfoForm.vehiclePageOn)"></UploadImg>
          </el-form-item>
          <el-form-item label="道路运输经营许可证" prop="vehicleRTPUTL" label-width="160px">
            <UploadImg :loading="confirmInfoForm.vehicleRTP.loading" :uploaded="confirmInfoForm.vehicleRTP.uploaded" :picUrl="confirmInfoForm.vehicleRTP.picUrl" @update:file="updateFile" @change="uploadVehicleRTP(confirmInfoForm.vehicleRTP)" @deleteImg="deleteImg(confirmInfoForm.vehicleRTP)"></UploadImg>
          </el-form-item>
          <el-form-item label="绑定车牌" prop="vehicleCarNumber">
            <el-input v-model="addForm.vehicleCarNumber" placeholder="请输入绑定车牌"></el-input>
          </el-form-item>
          <el-form-item label="道路运输证号" prop="vehicleRTP">
            <el-input v-model="addForm.vehicleRTP" placeholder="请输入道路运输证号"></el-input>
          </el-form-item>
          <el-form-item label="车身长度(m)" prop="VehicleLength">
            <el-input v-model="addForm.VehicleLength" placeholder="请输入车身长度"></el-input>
          </el-form-item>
          <el-form-item label="车身宽度(m)" prop="VehicleWidth">
            <el-input v-model="addForm.VehicleWidth" placeholder="请输入车身宽度"></el-input>
          </el-form-item>
          <el-form-item label="车牌颜色" prop="carNumberColor">
            <el-select v-model="addForm.carNumberColor" clearable placeholder="车牌颜色" style="width:358px">
              <el-option v-for="item in vehicleColorList" :key="item.Name" :label="item.Name" :value="item.Code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="载重量(吨)" prop="vehicleLoad">
            <el-input v-model="addForm.vehicleLoad" placeholder="请输入载重量(吨)"></el-input>
          </el-form-item>
          <el-form-item label="车辆类型" prop="vehicleType">
            <el-select v-model="addForm.vehicleType" clearable placeholder="车辆类型" style="width:358px">
              <el-option v-for="item in vehicleTypeList" :key="item.Name" :label="item.Name" :value="item.Code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="机动车有效期限" prop="vehicleLicenseValidDate">
            <el-date-picker v-model="addForm.vehicleLicenseValidDate" type="date"   value-format="yyyy-MM-dd" placeholder="请选择机动车有效期限" ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" size="medium" @click="add('addForm')">确认</el-button>
        <el-button size="medium" @click="closeDialog">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadImg from '@/components/commonCmpt/uploadImg'
import { UpdateVehicleInfo,addVehicleInfo,getVehicleInfo, delVehicleInfo, exportExcel, VehicleDeatils } from '@/api/platform/carManage/index'
import { uploadVehicleFace, uploadVehicleBack, uploadVehiclePageOn, uploadVehicleRTP } from '@/api/platform/carManage/verify'
import { getDataDict } from '@/api/common/common'
import { _dateFormat } from '@/utils/utils'
export default {
  data() {
    return {
      loading: false, // 表格加载
      title:'添加车辆',
      pagination: { //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      showAddDialog: false, //是否显示添加dialog
      // 搜索表单
      vehicleForm: {
        VehicleCarNumber: '',
       // VehicleLoad: '',
       // VehicleLicenseValidDate: '',
        VehicleType: ''// 车辆类型
      },
      addForm: {
        VehicleLength:'', //车身长度
        VehicleWidth:'', //车身宽度
        vehicleCarNumber: '', //绑定车牌
        vehicleRTP: '', //道路运输证号
        carNumberColor: '', //车辆颜色
        vehicleLoad: '', //载重量
        vehicleType: '', //车辆类型
        vehicleLicenseValidDate: '', //机动车有效期限
        vehicleLicenseFrontPageURL:'',
        vehicleLicenseSubPageOnURL:'',
        vehicleLicenseSubPageBackURL:'',
        vehicleRTPUTL:'',
      },
      // 表单校验
      addRule: {
        vehicleCarNumber: [{ required: true, message: '请输入绑定车牌', trigger: 'change' }],
        vehicleRTP: [{ required: true, message: '请输入道路运输证号', trigger: 'change' }],
        carNumberColor: [{ required: true, message: '请选择车辆颜色', trigger: 'change' }],
        vehicleLoad: [{ required: true, message: '请输入载重量', trigger: 'change' }],
        vehicleType: [{ required: true, message: '请选择车辆类型', trigger: 'change' }],
        vehicleLicenseValidDate:[{ required: true, message: '请选择机动车有效期限', trigger: 'change' }],
        vehicleLicenseFrontPageURL:[{ required: true, message: '请上传车辆行驶证正页', trigger: 'change' }],
        vehicleLicenseSubPageOnURL:[{ required: true, message: '请上传车辆行驶证副页(正面)', trigger: 'change' }],
        vehicleLicenseSubPageBackURL:[{ required: true, message: '请上传车辆行驶证副页(反面)', trigger: 'change' }],
        vehicleRTPUTL:[{ required: true, message: '请上传道路运输经营许可证', trigger: 'change' }],
        VehicleLength:[{ required: true, message: '请输入车身长度', trigger: 'change' }],
        VehicleWidth:[{ required: true, message: '请输入车身宽度', trigger: 'change' }]
      },
      tableData: [], //表格数据
      vehicleTypeList: [], //车辆类型
      vehicleColorList: [], //车辆颜色
      confirmInfoForm: {
        //车辆行驶证(正页)
        vehicleFace: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        //车辆行驶证副页(正面)
        vehicleBack: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        //车辆行驶证副页(反面)
        vehiclePageOn: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        //道路运输经营许可证
        vehicleRTP: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        plateNum:""
      }
    }
  },
  methods: {
    //下载Excel模板
    download() {
      window.open('https://res.gts56.com/fzkj/bsc/Driver/%E5%8F%B8%E6%9C%BA%E8%BD%A6%E8%BE%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%20.xls')
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false
      field.loading = false
      field.picUrl = ''
    },
    //上传行驶证副页反面照
    uploadVehiclePageOn(field) {
      field.loading = true
      let formData = new FormData()
      formData.append('file', this.currentFile)
      uploadVehiclePageOn(formData).then(res => {
        field.loading = false
        field.uploaded = true
        field.picUrl = res.data.vehicles
        this.addForm.vehicleLicenseSubPageBackURL = res.data.vehicles
      }).catch(() => {
        this.deleteImg(field)
      })
        .finally(() => {
          // field.loading = true
          // field.uploaded = true
        })
    },
    //上传车辆道路运输证照片
    uploadVehicleRTP(field) {
      field.loading = true
      let formData = new FormData()
      formData.append('file', this.currentFile)
      uploadVehicleRTP(formData).then(res => {
        field.loading = false
        field.uploaded = true
        field.picUrl = res.data.vehicles
        this.addForm.vehicleRTPUTL = res.data.vehicles
      }).catch(() => { })
        .finally(() => {
          field.loading = true
          field.uploaded = true
        })
    },
    //上传行驶证副页正面照
    uploadVehicleBack(field) {
      field.loading = true
      let formData = new FormData()
      formData.append('file', this.currentFile)
      uploadVehicleBack(formData).then(res => {
        field.loading = false
        field.uploaded = true
        field.picUrl = res.data.vehicles.vehicleBackUrl
        this.addForm.vehicleLicenseSubPageOnURL = res.data.vehicles.vehicleBackUrl
        let str = res.data.vehicles.overall_dimension.split("X")
        this.addForm.VehicleLength = Number(str[0]) / 1000
        this.addForm.VehicleWidth = Number(str[1]) / 1000
      }).catch(() => {
        this.deleteImg(field)
      })
        .finally(() => {
          // field.loading = true
          // field.uploaded = true
        })
    },
    //上传行驶证正页
    uploadVehicleFace(field) {
      field.loading = true
      let formData = new FormData()
      formData.append('file', this.currentFile)
      uploadVehicleFace(formData).then(res => {
        field.loading = false
        field.uploaded = true
        field.picUrl = res.data.vehicles.vehicleFaceUrl
        this.addForm.vehicleCarNumber = res.data.vehicles.plate_num
        this.addForm.vehicleLicenseFrontPageURL = res.data.vehicles.vehicleFaceUrl
      }).catch(() => {
        this.deleteImg(field)
      })
        .finally(() => {
          // field.loading = true
          // field.uploaded = true
        })
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file
    },
    //新增认证
    add(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            baseinfo: [],
            confirmInfo: [],
         //   type:0
          }
          params.baseinfo.push({
            vehicleID: this.vehicleID?this.vehicleID:'',
            vehicleCarNumber: this.addForm.vehicleCarNumber,
            vehicleLoad: this.addForm.vehicleLoad,
          //  isLeader: '',
            carNumberColor: this.addForm.carNumberColor,
          //  carNumberType: '',
            vehicleType: this.addForm.vehicleType,
           // vehiclePowerType: '',
          //  vehicleAttribution: '',
          //  vehicleROP: '',
            vehicleRTP: this.addForm.vehicleRTP,
          //  vehicleVIN: '',
          //  vehicleLicesenAuthority: '',
         //   vehicleMaster: '',
          //  vehicleUsage: '',
          //  vehicleTotalWeight: '',
          //  vehicleLoadWeight: '',
           // vehicleLicenseRegDate: '',
           // vehicleLicenseAwardDate: '',
          //  powerNumber: '',
          //  vehicleLicenseArchives: '',
           // vehicleAxlesNumber: '',
           // vehicleHeight: '',
           // vehicleRTPDate: '',
            vehicleLicenseValidDate: this.addForm.vehicleLicenseValidDate,
           // vehicleBuyDate: '',
           // cInsuranceDate: '',
           // bInsuranceDate: '',
           // inspectAnnuallyDate: '',
           // status: '',
           // remark: '',
            VehicleWidth:this.addForm.VehicleWidth,
            VehicleLength:this.addForm.VehicleLength
          })
          params.confirmInfo.push({
            vehicleID: this.vehicleID?this.vehicleID:'',
            vehicleLicenseFrontPageURL: this.confirmInfoForm.vehicleFace.picUrl,
            vehicleLicenseSubPageOnURL: this.confirmInfoForm.vehicleBack.picUrl,
            vehicleLicenseSubPageBackURL: this.confirmInfoForm.vehiclePageOn.picUrl,
            vehicleRTPUTL: this.confirmInfoForm.vehicleRTP.picUrl,
          })
          if(this.vehicleID){
            UpdateVehicleInfo(params).then(res => {
              this.pagination.page = 1;
              this.getVehicleInfo();
              this.showAddDialog = false;
            }).catch(() => { })
              .finally(() => {
                this.pagination.page = 1
              })
          }else{
            addVehicleInfo(params).then(res => {
              this.pagination.page = 1;
              this.getVehicleInfo();
              this.showAddDialog = false;
            }).catch(() => { })
              .finally(() => {
                this.pagination.page = 1
              })
          }
        }
      })
    },
    //搜索
    search(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //搜索前初始化page
          this.pagination.page = 1;
          this.getVehicleInfo();
        }
      })
    },
    //清空
    resetForm(formName) {
      this.vehicleForm = {
        VehicleCarNumber: '',
        VehicleType: ''
      }
      this.pagination.page = 1;
      this.getVehicleInfo();
    },
    //导出excel
    exportExcel() {
      let params = {
        fileType: 'vehicleinfo',
        fileName: '车辆导出',
      }
      exportExcel(params).then(res => {
        window.open(res)
      })
    },
    //tbale选中改变
    tableSelectionChange(e) {
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getVehicleInfo();
    },
    //获取车辆列表
    getVehicleInfo() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        VehicleCarNumber: this.vehicleForm.VehicleCarNumber,
        VehicleType: this.vehicleForm.VehicleType
      }
      this.loading = true
      getVehicleInfo(params).then(res => {
        this.tableData = res.data.DataList.map(item => {
          if (item.Status == 0) {
            item.Status = '上传未校验'
          } else if (item.Status == 1) {
            item.Status = '已校验通过'
          } else if (item.Status == 2) {
            item.Status = '校验未通过'
          }
          return item
        })
        this.pagination.total = Number(res.data.TotalCount)
        this.loading = false
      }).catch(err => {
        if (err.code === 403) {
          this.$router.push('/power/nopermission')
        }
      });
    },
    //获取车辆类型列表
    getVehicleList() {
      getDataDict({ type: 2 }).then(res => {
        this.vehicleTypeList = res.data
      })
    },
    //获取车辆颜色列表
    getVehicleColorList() {
      getDataDict({ type: 3 }).then(res => {
        this.vehicleColorList = res.data
      })
    },
    //删除车辆
    delVehicleInfo(item) {
      this.$confirm('此操作将删除该车辆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delVehicleInfo({
          vehicleIds: item.VehicleID
        }).then(res => {
          //获取车辆列表
          this.getVehicleInfo()
        })
      }).catch(() => {
      })
    },
    // 添加车辆
    addvehicle(){
      this.title = '添加车辆'
      this.confirmInfoForm.vehicleFace.picUrl = ''
      this.confirmInfoForm.vehicleBack.picUrl = ''
      this.confirmInfoForm.vehiclePageOn.picUrl = ''
      this.confirmInfoForm.vehicleRTP.picUrl =''
      this.addForm = {
        vehicleCarNumber: '', //绑定车牌
        vehicleRTP: '', //道路运输证号
        carNumberColor: '', //车辆颜色
        vehicleLoad: '', //载重量
        vehicleType: '', //车辆类型
        vehicleLicenseValidDate:'', //机动车有效期限
        vehicleLicenseFrontPageURL:'',
        vehicleLicenseSubPageOnURL:'',
        vehicleLicenseSubPageBackURL:'',
        vehicleRTPUTL:'',
        VehicleLength:'', //车身长度
        VehicleWidth:'', //车身宽度
      }
      this.showAddDialog = true
      // this.$refs['addForm'].resetFields();
    },
    closeDialog(){
      this.$refs['addForm'].resetFields();
      this.showAddDialog = false
    },
    handleClose(done) {
      done();
      this.$refs['addForm'].resetFields();
    },
    //编辑车辆
    goVerifyPage(item) {
      this.title = '编辑车辆'
      VehicleDeatils({vehicleId:item.VehicleID}).then(res=>{
        this.vehicleID = res.data.vehicles[0].VehicleID
        this.confirmInfoForm.vehicleFace.picUrl = res.data.vehicles[0].VehicleLicenseFrontPageURL
        this.confirmInfoForm.vehicleBack.picUrl = res.data.vehicles[0].VehicleLicenseSubPageOnURL
        this.confirmInfoForm.vehiclePageOn.picUrl = res.data.vehicles[0].VehicleLicenseSubPageBackURL
        this.confirmInfoForm.vehicleRTP.picUrl = res.data.vehicles[0].VehicleRTPUTL
        this.plateNum =  res.data.vehicles[0].VehicleCarNumber
        this.addForm = {
          vehicleCarNumber: res.data.vehicles[0].VehicleCarNumber, //绑定车牌
          vehicleRTP: res.data.vehicles[0].VehicleRTP, //道路运输证号
          carNumberColor: res.data.vehicles[0].CarNumberColor, //车辆颜色
          vehicleLoad: res.data.vehicles[0].VehicleLoad, //载重量
          vehicleType: res.data.vehicles[0].VehicleType, //车辆类型
          vehicleLicenseValidDate:res.data.vehicles[0].VehicleLicenseValidDate, //机动车有效期限
          vehicleLicenseFrontPageURL:res.data.vehicles[0].VehicleLicenseFrontPageURL,
          vehicleLicenseSubPageOnURL:res.data.vehicles[0].VehicleLicenseSubPageOnURL,
          vehicleLicenseSubPageBackURL:res.data.vehicles[0].VehicleLicenseSubPageBackURL,
          vehicleRTPUTL:res.data.vehicles[0].VehicleRTPUTL,
          VehicleLength:res.data.vehicles[0].VehicleLength, //车身长度
          VehicleWidth:res.data.vehicles[0].VehicleWidth, //车身宽度
        }
      })
      this.showAddDialog = true
      this.$refs['addForm'].resetFields();
      // this.$router.push({ path: '/transport/vehicleManage/verify', query: { id: item.VehicleID } })
    }
  },
  created() {
    //获取车辆列表
    this.getVehicleInfo()
    //获取车辆类型列表
    this.getVehicleList()
    //获取车辆颜色列表
    this.getVehicleColorList()
  },
  components: {
    UploadImg
  }
}
</script>
<style lang="scss">
.vehicleManagePage{
.el-dialog {
  margin-top: 4vh !important;
}
}
</style>
<style scoped lang="scss">
@import '../../../assets/style/variable.scss';
.vehicleManagePage {
  .btn_box {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
  }
  .head {
    display: flex;
    .btn{
      vertical-align: top;
      margin: 10px 0 0 16px;
    }
  }
  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }
    ::v-deep .el-dialog__footer{
      border-top: 1px solid #eee;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
    .uploadArea {
      margin-top: 20px;
      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .hint {
      display: flex;
      justify-content: center;
    }
    .choose {
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
  }
  .btns {
    margin-bottom: 20px;
  }
  .vehicleForm {
    // padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    min-width: 1110px;
  }
  .addForm {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 480px;
    }
    .el-date-editor {
      width: 100% !important;
    }
  }
  .pagination {
    margin-top: 10px;
  }
}
</style>